#about {
  position: relative;
  background: url("../../assets/bg_color_about.png");
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
  background-color: var(--white-color);
}

.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.button {
  padding: 1rem 2rem;
  border-radius: 30px;
  border: none;
  background-color: var(--secondary-color);

  font-weight: 500;
  color: var(--white-color);
  outline: none;
  margin: 2rem 0 0 0;
  font-family: var(--font-base);

  transition: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 185px;
  height: 44px;

  &:before,
  &:after {
    content: "";
    position: absolute;
    background-color: white;
  }

  &:before {
    height: 8px;
    width: 8px;
    left: -8px;
    top: 6px;
    border-radius: 50%;
    transition: left 0.2s ease;
  }

  &:after {
    height: 8px;
    width: 8px;
    right: -10px;
    bottom: 6px;
    border-radius: 50%;
    transition: right 0.2s ease;
  }

  &:hover:before,
  &:hover:after {
    animation: spin 2s linear infinite;
  }
  &:hover:before {
    left: 14px;
  }

  &:hover:after {
    right: 14px;
  }
}

.app__profiles {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.app__profile-item {
  width: 190px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 2rem;

  img {
    width: 100%;
    height: 170px;
    border-radius: 15px;
    object-fit: cover;
  }

  @media screen and (min-width: 2000px) {
    width: 370px;
    margin: 2rem 4rem;

    img {
      height: 320px;
    }
  }
}
.about.p-text {
  margin: auto;
  text-align: justify;
  padding: 10px;
  @media screen and (min-width: 700px) {
    width: 500px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
