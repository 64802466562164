@import url("https://fonts.googleapis.com/css2?family=Bona+Nova&family=Poppins:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

:root {
  --font-base: "Poppins", sans-serif;

  --primary-color: #edf2f8;
  --secondary-color: rgb(47, 46, 65, 100%);
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --brown-color: #46364a;
  --white-color: #ffffff;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
